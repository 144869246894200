

const mediadata = [
    {
        image: "https://img.etimg.com/thumb/width-1200,height-900,imgsize-200884,resizemode-1,msid-86225490/markets/cryptocurrency/buyucoin-launches-crypto-sip-facility-with-10-tokens.jpg",
        link: 'https://www.umm.digital/'
    },

    {
        image: "https://d32ijn7u0aqfv4.cloudfront.net/wp/wp-content/uploads/raw/SOIN21140_1560x880.jpg",
        link: 'https://www.umm.digital/'
    },
    {
        link: 'https://www.umm.digital/'
    },

    {
        image: "https://img.etimg.com/thumb/width-1200,height-900,imgsize-200884,resizemode-1,msid-86225490/markets/cryptocurrency/buyucoin-launches-crypto-sip-facility-with-10-tokens.jpg",
        link: 'https://www.umm.digital/'
    },

    {
        image: "https://d32ijn7u0aqfv4.cloudfront.net/wp/wp-content/uploads/raw/SOIN21140_1560x880.jpg",
        link: 'https://www.umm.digital/'
    },
    {
        link: 'https://www.umm.digital/'
    },

]

export default mediadata;